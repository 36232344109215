
export class HealthAndWellBeingController {
        
    saved: boolean;

    static $inject = ['$scope', '$q', 'dialogs', 'HelpTextsAdmin',
        'allergies', 'physicalHealth', 'mentalHealth', 'medicalTreatments', 'otherHealthConditions', 'restrictedHealthInformation', 'vaccinations'];
    constructor(private $scope, private $q, private dialogs, private HelpTextsAdmin,
        private allergies,
        private physicalHealth,
        private mentalHealth,
        private medicalTreatments,
        private otherHealthConditions,
        private restrictedHealthInformation,
        private vaccinations) {

    }

    save() {
        this.$scope.$broadcast('submitted', true);

        var promisses = [];
        var form = this.$scope.interviewReportForm;
        if (form['Allergies'].$dirty) {
            promisses.push(
                this.HelpTextsAdmin.save({ module: 'Allergies' }, this.allergies).$promise);
        }
        if (form['PhysicalHealth'].$dirty) {
            promisses.push(
                this.HelpTextsAdmin.save({ module: 'PhysicalHealth' }, this.physicalHealth).$promise);
        }
        if (form['MentalHealth'].$dirty) {
            promisses.push(
                this.HelpTextsAdmin.save({ module: 'MentalHealth' }, this.mentalHealth).$promise);
        }
        if (form['MedicalTreatments'].$dirty) {
            promisses.push(
                this.HelpTextsAdmin.save({ module: 'MedicalTreatments' }, this.medicalTreatments).$promise);
        }
        if (form['OtherHealthConditions'].$dirty) {
            promisses.push(
                this.HelpTextsAdmin.save({ module: 'OtherHealthConditions' }, this.otherHealthConditions).$promise);
        }
        if (form['RestrictedHealthInformation'].$dirty) {
            promisses.push(
                this.HelpTextsAdmin.save({ module: 'RestrictedHealthInformation' }, this.restrictedHealthInformation).$promise);
        }
        if (form['Vaccinations'].$dirty) {
            promisses.push(
                this.HelpTextsAdmin.save({ module: 'Vaccinations' }, this.vaccinations).$promise);
        }

        this.$q.all(promisses)
            .then(() => {
                form.$setPristine();
                $('html, body').animate({ scrollTop: 860 }, 200);
                this.saved = true;
            }, () => {
                this.dialogs.error('Error', 'We are sorry but your form could not be saved. Please try again in 5 minutes. If this problem continues, please contact us at: <a target="_blank" href="mailto:support@aupairamerica.co.uk">support@aupairamerica.co.uk<a>');
            });
    }
}

angular
    .module('app')
    .controller('HelpText.HealthAndWellBeingController', HealthAndWellBeingController);