import { ReferenceType } from '../../../../models/reference/ReferenceType';


class ReferenceModalController {

    referenceSaveButtonText: string = 'Save Reference';
        
    static $inject = ['$scope', 'dialogs', '$uibModalInstance', 'Reference', 'title', 'reference', 'canEditRef', 'ApplicationOnAllowPaperReference'];
    constructor(private $scope, private dialogs, private $uibModalInstance, private Reference, public title: string, public reference, private canEditRef, private ApplicationOnAllowPaperReference) {

        if (canEditRef && (reference.status === 1 || reference.status === 3)) {
            $scope.$watch(() => reference.email, () => {
                if (reference.email && reference.previousEmail !== reference.email) {
                    this.referenceSaveButtonText = 'Resend';
                    reference.isResendNotification = true;
                }
                else {
                    this.referenceSaveButtonText = 'Save Reference';
                    reference.isResendNotification = false;
                }
            })
        }

        this.ApplicationOnAllowPaperReference.get({
            applicationId: reference.applicationId
        }, (result) => {
            reference.isOnAllowPaperReference = result.onAllowPaperReference;

            //it is causing issue
            //FREYAS-838 - Clicking edit on an online reference changes the type from online to paper
            ////if (reference.isOnAllowPaperReference) {
            ////    reference.uploadPaperReferences = true;
            ////}
        });

        if (reference.status !== 1) {
            //If the reference statuses is not requested, 
            //the user should not be able to changes the email.The button should say save and the reference should not be resent.
            //If the reference statuses is Awaiting paper upload ,
            //Email will not be send in paper upload.   but text would be 'Save Reference'
            this.referenceSaveButtonText = 'Save Reference';
        }
        //else if (reference.status === 1) {
        //    //If the reference statuses is Requested             
        //    this.referenceSaveButtonText = 'Resend';
        //}
    }

    private referenceType(): ReferenceType {
        if (!this.reference)
            return ReferenceType.Unknown;

        return <ReferenceType>this.reference.referenceType;
    }

    cancel() {
        this.$uibModalInstance.dismiss('cancel');
    }

    isCharacter = () => this.referenceType() === ReferenceType.Character;
    isEducare = () => this.referenceType() === ReferenceType.Educare;
    isChildcare = () => this.referenceType() === ReferenceType.Childcare;
    isFamily = () => this.referenceType() === ReferenceType.PreviousHostFamily;

    check() {

        this.$scope.$broadcast('submitted', true);
        if (this.reference.$validation()) {

            var confirmRequest = this.dialogs.confirm('Complete Reference Check', 'Are you sure that you have completed this telephone reference check correctly?  You will be unable to edit these values unless problems are found by your agency or the Au Pair head office.');

            confirmRequest.result.then(() => {
                this.Reference
                    .create(this.reference)
                    .$promise
                    .then((data) => {
                        this.$uibModalInstance.close(data);
                    }, () => {
                        this.dialogs.error('Error', 'We are sorry but your reference check could not be saved. Please try again in 5 minutes. If this problem continues, please contact us at: <a target="_blank" href="mailto:support@aupairamerica.co.uk">support@aupairamerica.co.uk<a>');
                    });

            });
        }
    }

    // save an existing entry 
    save() {
        if (this.reference.isResendNotification) {
            var confirmRequest = this.dialogs.confirm('Save Reference', `By changing the email, this reference will be sent to <strong>${this.reference.email}</strong>. Are you sure that you want to resend this reference?`);
            confirmRequest.result.then(() => {
                this.saveReference();
            });
        }
        else {
            this.saveReference();
        }
    }

    saveReference() {
        this.$scope.$broadcast('submitted', true);
        if (this.reference.$validation()) {

            this.Reference
                .save(this.reference)
                .$promise
                .then((data) => {
                    this.$uibModalInstance.close(data);
                    if (this.reference.isResendNotification) {
                        window.location.reload();
                    }
                }, () => {
                    this.dialogs.error('Error', 'We are sorry but your reference request could not be saved. Please try again in 5 minutes. If this problem continues, please contact us at: <a target="_blank" href="mailto:support@aupairamerica.co.uk">support@aupairamerica.co.uk<a>');
                });
        }
    }

    // add a new entry
    add() {

        this.$scope.$broadcast('submitted', true);
        if (this.reference.$validation()) {
            var confirmRequest;
            if (this.reference.uploadPaperReferences) {
                confirmRequest = this.dialogs.confirm('Paper Upload Reference', `By requesting a Paper Reference, you will need to upload the mandatory documents. Are you sure that you wish to request a Paper Reference?`)
                // if they are sure, then we do the save...                    
            }
            else {
                // double check that they are sure they want to send a reference at this point
                confirmRequest = this.dialogs.confirm('Request Reference', `By requesting a reference, an email will be sent to <strong>${this.reference.email}</strong>. Are you sure that you want to send this request now?`)
                // if they are sure, then we do the save...
            }

            confirmRequest.result.then(() => {
                this.createReference();
            });
        }
    }
    createReference() {
        this.Reference
            .create(this.reference)
            .$promise
            .then((data) => {
                this.$uibModalInstance.close(data);
            }, (response) => {
                var message = (response.data && response.data.message) || 'We are sorry but your reference request could not be saved. Please try again in 5 minutes. If this problem continues, please contact us at: <a target="_blank" href="mailto:support@aupairamerica.co.uk">support@aupairamerica.co.uk<a>';
                this.dialogs.error('Error', message);
            });
    }
}

angular
    .module('app')
    .controller('ReferenceModalController', ReferenceModalController);