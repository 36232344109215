import _ from 'lodash-es'
import moment from 'moment'
import { ReadmitChangeType } from './../action/readmit/ReadmitChangeType'
import { ReadmitChange } from './../action/readmit/ReadmitChange'
import { Data } from '@angular/router';

export class InvalidApplicatHomeController {

    instructionalVideoUrl = 'https://aupairamericalive.s3.amazonaws.com/resources/I/Instructional Tax Video - Guidance.mp4';
    applicationStatus: string;
    birthDate: Date;
    canReadmit: boolean;
    instructionalVideoPreSignedUrl: string;

    static $inject = ['Auth', 'applicationObservable', 'ApplicationStatus', '$window', 'dialogs', 'Readmit', 'ResourceSigner']
    constructor(private Auth, private applicationObservable, ApplicationStatus, private $window, private dialogs, private Readmit, private ResourceSigner) {

        ApplicationStatus.get({
            applicationId: Auth.user.roleDetails.applicationId
        }, (status) => {

            var applicationStatus;
            switch (status.statusReason) {
                case 0:  // NoFurtherInformation //TODO:: move this to enum
                    break;
                case 1:  // NoFurtherInformation //TODO:: move this to enum
                    applicationStatus = 'cancelled';
                    break;
                case 29: // APD - Au Pair Departed
                case 23:
                    applicationStatus = 'auPairDeparted';
                    this.presignUrl();
                    break;
                case 42: // Age over 27 //TODO:: move this to enum
                    applicationStatus = 'tooOld';
                    break;
                case 43: // Age under 17 //TODO:: move this to enum
                    applicationStatus = 'tooYoung';
                    break;
                case 44: // Male Not Placed //TODO:: move this to enum
                    applicationStatus = 'maleNotPlaced';
                    break;
                case 45: // US National //TODO:: move this to enum
                    break;
                case 51: // WrongCountry //TODO:: move this to enum
                    applicationStatus = 'wrongCountry';
                    break;
            }

            switch (status.status) {
                case 'ARC':
                    applicationStatus = 'archive';
                    break;
                case 'REJ':
                    applicationStatus = 'rejected';
                    break;
                case 'AWL':
                    applicationStatus = 'awol';
                    this.presignUrl();
                    break;
                case 'APD':
                    applicationStatus = 'auPairDeparted';
                    this.presignUrl();
                    break;
                case 'TXP':
                    applicationStatus = 'timeExpired';
                    this.presignUrl();
                    break;
            }

            if (applicationStatus === 'tooYoung') {
                applicationObservable.subscribe(applicationInstance => {
                    this.birthDate = applicationInstance.birthDate;
                    this.canReadmit = moment().diff(this.birthDate, 'years') > 17


                });
            }
            this.applicationStatus = applicationStatus;
        });

        
            
    }

    readmit() {
        let user = this.Auth.user;

        let data = {
            applicationId: user.roleDetails.applicationId,
            userId: user.userId,
            author: user.firstName + ' ' + user.lastName,
            changeType: ReadmitChangeType.BirthDate,
            changeModel: {
                birthDate: this.birthDate
            }
        };

        this.Readmit.update({ applicationId: this.Auth.user.roleDetails.applicationId }, data)
            .$promise
            .then((results) => {
                this.$window.location.reload();
            }, (error) => {
                let reason = 'We are sorry but the application could not be readmit. ';
                reason += error && _.isString(error.data) && error.data.length > 0
                    ? `${error.data}.`
                    : 'Please try again in 5 minutes.';

                return this.dialogs
                    .error('Error', reason)
                    .result
            });
    }

    presignUrl() {
        this.ResourceSigner.preSign({ url: this.instructionalVideoUrl }, (response) => {
            this.instructionalVideoPreSignedUrl = response.url;
        });
    }
}
angular
    .module('app')
    .controller('InvalidApplicantHomeController', InvalidApplicatHomeController);