import { ApplicationStatus } from './ApplicationStatus'
import { AssignToInterviwer } from '../action/assignToInterviewer/AssignToInterviewer'
import { ArchiveApplicant } from '../action/archiveApplicant/archiveApplicant'
import { AssignToAgency } from '../action/assignToAgency/AssignToAgency'
import { SendBackToAgency } from '../action/sendBack/SendBackToAgency'
import { AssignCPI } from '../action/assignCPI/assignCPI'
import { setFlightDates } from '../action/flightDates/setFlightDates'
import { Readmit } from '../action/readmit/readmit'
import { Reinstate } from '../action/reinstate/Reinstate'
import { CancelApplication } from '../action/cancelApplication/cancelApplication'
import { FailApplication } from '../action/failApplication/failApplication'
import { ITep } from '../action/iTep/iTep'
import { setTXPDates } from '../action/TXPDates/setTXPDates'
import { ScheduleInterview } from '../action/scheduleInterview/scheduleInterview'
import { HealthStatus } from '../action/healthStatus/healthStatus'
import moment from 'moment'
import _ from 'lodash-es'
import { debug } from 'console'
import { ItemGroupId } from '../../models/ItemGroup'

//TODO:: move to user actions
enum ActionName {
    ASSIGN_INTERVIEWER = 1,
    ALLOW_PAPER_REFERENCE = 2,
    INFANT_QUALIFIED = 3,
    IQ_OLDER_KIDS = 4,
    ON_HOLD = 5,
    ASSIGN_TO_AGENCY = 6,
    ARCHIVE = 7,
    ITEP = 8,
    SCHEDULE_INTERVIEW = 9,
    HEALTH_STATUS = 10
}

class ApplicationController {

    ActionName = ActionName

    applicant: any
    application: any

    selectedMenuClass: string
    document
    applicationId: number
    applicantUserId: number
    isInfantQualified: boolean
    applicationFinanceDetails: any
    isInfantQualifiedOlderKids: boolean
    isOnHold: boolean
    isOnAllowPaperReference: boolean
    canApplicationBeSentBack: boolean
    applicationAgencyId: number
    applicationInterviewerId: number
    cpiStatusDescription: string
    cpiStatusId: number
    earliestFlightDate: string
    latestFlightDate: string
    txpDate: string
    canCancelApplication: boolean
    canFailApplication: boolean
    canApplicationAccept: boolean
    isApplicationAccepted: boolean
    applicationStatusSummaryList: any
    canAgencyAssigned: boolean
    isApplicationStatusAWA: boolean
    canSetSuppressInsurance: boolean 
    profileUpdated: boolean = false
    showApplicationStartedReview: boolean = false
    isCertificateSuppressed: boolean
    canSuppressCertificate: boolean
    displayShareURL: boolean
    shareApplicationURL: string
    displayVisaAppointment: boolean
    isPlaced: boolean
    isActive: boolean
    isAwaitingAssessment: boolean
    isToBeMatched: boolean
    isCancelled: boolean
    isTimeExpired: boolean
    canAssignToInterviewer: boolean
    placementPackDesc: string
    isInvoicePrinted: boolean = false;
    invoicePrintDesc: string
    showApplicationMessage: boolean = true;
    showApplicationToEdit: boolean = false;
    canArchive: boolean;
    canUnarchive: boolean;
    canReadmit: boolean;
    canReinstate: boolean;
    isLocked: boolean;
    rightFirstTime: boolean;
    canScheduleInterview: boolean;

    readmitSummaryList: string[];
    reinstateSummaryList: string[];

    financeMenuEnabled: boolean;
    invoice: any;

    placementPackURL: string;
    invoiceURL: string;
    toggleApplicantInformation: boolean = true;
    toggleManagementInformation: boolean = true;
    applicationStatus: string;
    canHealthStatus: boolean = false;
    canDisplayHealthStatus: boolean = false;

    private applicationInstance;

    static $inject = ['$rootScope', '$scope', 'ApplicationStatus', 'AssignCPI', 'ApplicationInfantQualified',
        'ApplicationIQOlderKids', 'ApplicationOnHold', 'ApplicationOnAllowPaperReference', 'Interviewer',
        'Auth', '$uibModal', 'dialogs', 'applicationId', 'ApplicationFlightDetails', 'ApplicationTXPDetails',
        'AcceptApplication', 'ReviewApplication', 'ApplicationCertificate', 'ApplicationShareLink',
        'ApplicationPlacement', 'Readmit', 'Reinstate', 'applicationObservable', 'PlacementLetter', '$window', 'PrintInvoiceForm',
        'LockedStatus', 'ApplicationFinanceDetails', 'ApplicationRightFirstTime', 'Event', 'review', 'HealthReviewStatus'];
    constructor(private $rootScope, private $scope, private ApplicationStatus, private AssignCPI, private ApplicationInfantQualified,
        private ApplicationIQOlderKids, private ApplicationOnHold, private ApplicationOnAllowPaperReference, private Interviewer,
        private Auth, private $uibModal, private dialogs, applicationId, 
        private ApplicationFlightDetails, private ApplicationTXPDetails, private AcceptApplication, private ReviewApplication, private ApplicationCertificate,
        private ApplicationShareLink, private ApplicationPlacement, private Readmit, private Reinstate,
        applicationObservable, private PlacementLetter, private $window, private PrintInvoiceForm,
        private LockedStatus, private ApplicationFinanceDetails, private ApplicationRightFirstTime, private Event, private review, private HealthReviewStatus) {
            
        this.applicationId = applicationId;
            
        applicationObservable.subscribe(i => {
            this.applicationInstance = i;
            this.applicationStatus = i.status;
            this.updateApplicationStatus(this.applicationInstance);


            this.Event.user({
                id: i.userId
            }, (events) => {
                this.canScheduleInterview = !_.some(events, (e) => e.entryType == 1 || e.entryType == 5);
            });
        });


        this.init()

        $scope.$on('changeInterviewerAndAgency', (event, data) => {
            if (data.agencyId)
                this.applicationAgencyId = data.agencyId;

            if (data.interviewerId)
                this.applicationInterviewerId = data.interviewerId;
            else if (!data.interviewerSelected)
                this.applicationInterviewerId = 0;

            if (data.canApplicationBeSentBack)
                this.canApplicationBeSentBack = data.canApplicationBeSentBack;
        })

        $scope.$on('profileUpdated', (event, data) => {
            this.getAcceptApplicationDetails();
        })

        $scope.$on('changeApplicationStatus', (event, canApplicationBeSentBack) => {
            this.canApplicationBeSentBack = canApplicationBeSentBack;
        })

        $scope.$on('changeCPIStatus', (event, data) => {
            this.cpiStatusDescription = data.CPIStatusDescription
            this.cpiStatusId = data.CPIStatusId;
            this.getAcceptApplicationDetails();
        })

        $scope.$on('changeFlightDates', (event, selectedEarliestFlightDate, selectedLatestFlightDate) => {
            this.earliestFlightDate = selectedEarliestFlightDate;
            this.latestFlightDate = selectedLatestFlightDate;
            this.getAcceptApplicationDetails();
        })

        $scope.$on('changeTXPDates', (event, selectedTXPDate) => {
            this.txpDate = selectedTXPDate;
            this.getAcceptApplicationDetails();
        })

        $scope.$on('cancelFailApplication', (event, data) => {
            this.canCancelApplication = this.canFailApplication = false;
            this.canApplicationBeSentBack = false;
        });

        $scope.$on('archiveApplication', (event) => {
            this.canUnarchive = true; 
        });

        $scope.$on('healthReviewStatusChange', (event) => {
            //todo test
            this.HealthReviewStatus.canHealthStatus({
                applicationId: this.applicationId
            }, (result) => {
                this.canHealthStatus = result.canHealthStatus
            })
        });

        $scope.$on('push:itemReviewUpdated', (event, data) => {
            this.getReinstateApplicationDetails();
            this.getReadmitApplicationDetails();
        })

        $scope.$on('sideMenu:enableFinance', (event, invoice) => {
            this.financeMenuEnabled = true;
            this.selectedMenuClass = 'finance';
            this.invoice = invoice;
        })

        $scope.$on('sideMenu:updateFinance', (event, invoice) => {
            this.financeMenuEnabled = true;
            this.invoice = invoice;
        })

        $scope.$on('sideMenu:disableFinance', (event, document) => {
            this.financeMenuEnabled = false;
            this.invoice = undefined;
            if (this.selectedMenuClass == 'finance') {
                this.selectedMenuClass = 'actions';
            }
        })

        $scope.$on('document:selected', (event, document) => {
            this.selectedMenuClass = 'document-preview'
            this.document = document
        })
        this.selectedMenuClass = 'actions';
    }

    expandCollapseApplicantInformation() {
        this.toggleApplicantInformation = !this.toggleApplicantInformation;
    }
    expandCollapseManagementInformation() {
        this.toggleManagementInformation = !this.toggleManagementInformation;
    }

    updateApplicationStatus(applicationInstance) {
        var user = this.Auth.user;

        this.applicantUserId = applicationInstance.userId;
        this.applicationInterviewerId = applicationInstance.interviewerId;
        this.applicationAgencyId = applicationInstance.agencyId;

        this.isApplicationStatusAWA = applicationInstance.status === ApplicationStatus.AwaitingAssessment;
        this.canApplicationBeSentBack = applicationInstance.status === ApplicationStatus.AwaitingAssessment;
        this.canCancelApplication = user.isOfficer;
        this.canFailApplication = applicationInstance.status === ApplicationStatus.AwaitingAssessment;
        this.isApplicationAccepted = applicationInstance.status === ApplicationStatus.ToBeMatched;
        this.canAgencyAssigned = applicationInstance.status === ApplicationStatus.Registered || applicationInstance.status === ApplicationStatus.AwaitingAssessment || applicationInstance.status === ApplicationStatus.Online;
        this.canSuppressCertificate = applicationInstance.status === ApplicationStatus.Active || applicationInstance.status === ApplicationStatus.Departed || applicationInstance.status === ApplicationStatus.AbsentWithoutLeave || applicationInstance.status === ApplicationStatus.Inactive || applicationInstance.status === ApplicationStatus.InRematch;
        this.canArchive = applicationInstance.status === ApplicationStatus.Registered || applicationInstance.status === ApplicationStatus.Online;
        this.canUnarchive = applicationInstance.status === ApplicationStatus.Archive;
        this.isPlaced = applicationInstance.status === ApplicationStatus.Placed;
        this.isActive = applicationInstance.status === ApplicationStatus.Active;
        this.isAwaitingAssessment = applicationInstance.status === ApplicationStatus.AwaitingAssessment;
        this.isToBeMatched = applicationInstance.status === ApplicationStatus.ToBeMatched;
        this.isCancelled = applicationInstance.status === ApplicationStatus.Cancelled;
        this.isTimeExpired = applicationInstance.status === ApplicationStatus.TimeExpired;
        this.canAssignToInterviewer = applicationInstance.status === ApplicationStatus.Registered || applicationInstance.status === ApplicationStatus.Online;
        this.canSetSuppressInsurance = applicationInstance.status === ApplicationStatus.Online || applicationInstance.status === ApplicationStatus.AwaitingAssessment || applicationInstance.status === ApplicationStatus.ToBeMatched || applicationInstance.status === ApplicationStatus.Placed;

        if (this.Auth.user.isOfficer) {
            this.getAcceptApplicationDetails();
            this.getReadmitApplicationDetails();
            this.getReinstateApplicationDetails();
            this.getIsAccountLocked();

            // If user is placed or active, go and check if placement-pack has been printed
            if (this.isPlaced || this.isActive) {
                this.ApplicationPlacement.placementPrinted({
                    applicationId: this.applicationId
                }, (result) => {
                    if (result.isPlacementPackPrinted) {
                        this.placementPackDesc = "View";
                    }
                    else {
                        this.placementPackDesc = "View";
                    }
                    });                
            }
        }

        var page = this.review.getPage(ItemGroupId.HealthAndWellBeing);
        if (applicationInstance.status === ApplicationStatus.Online && page && page.isComplete && applicationInstance.healthStatusId &&
            (
                (user.isOfficer && applicationInstance.healthStatusId == 3) ||
                (user.isAgent && [1, 4, 5, 6, 7].includes(applicationInstance.healthStatusId))
            ))
        {
            this.HealthReviewStatus.canHealthStatus({
                applicationId: this.applicationId
            }, (result) => {
                this.canDisplayHealthStatus = result.canHealthStatus;
            })
        }

    }

    init() {
        var user = this.Auth.user;
        if (user.isOfficer) {
            this.showApplicationMessage = false;
            this.showApplicationToEdit = true;  //only juno user can edit application

            this.ApplicationFlightDetails.get({ applicationId: this.applicationId }, (result) => {
                this.earliestFlightDate = result.flightDateEarliestDisplay
                this.latestFlightDate = result.flightDateLatestDisplay
            })

            this.ApplicationTXPDetails.get({ applicationId: this.applicationId }, (result) => {
                this.txpDate = result.txpDate
            })

            this.ApplicationInfantQualified.get({
                applicationId: this.applicationId
            }, (result) => {
                this.isInfantQualified = result.infantQualified
            })  

            this.ApplicationFinanceDetails.get({
                applicationId: this.applicationId
            }, (result) => {
                this.applicationFinanceDetails = result;
            })

            this.ApplicationIQOlderKids.get({
                applicationId: this.applicationId
            }, (result) => {
                this.isInfantQualifiedOlderKids = result.infantQualifiedForOlderKids
            })

            this.AssignCPI.get({
                applicationId: this.applicationId
            }, (result) => {
                if (result.cpiStatusId > 0) {
                    this.cpiStatusDescription = result.cpiStatusDescription
                    this.cpiStatusId = result.cpiStatusId
                }
                else {
                    this.cpiStatusDescription = 'Select 16PF'
                    this.cpiStatusId = 0
                }
            })


            this.ApplicationCertificate.get({
                applicationId: this.applicationId
            }, (result) => {
                this.isCertificateSuppressed = result.isSuppressed
            })

            this.ApplicationRightFirstTime.get({
                applicationId: this.applicationId
            }, (result) => {
                this.rightFirstTime = result.rightFirstTime;
            })

            this.ApplicationShareLink.get({
                applicationId: this.applicationId
            }, (result) => {
                this.displayShareURL = result.displayShareButton;
                this.shareApplicationURL = result.shareURL
            });

            //Check if invoice printed
            this.ApplicationPlacement.isInvoicePrinted({
                applicationId: this.applicationId
            }, (result) => {
                this.isInvoicePrinted = result.isInvoicePrinted;

                if (result.isInvoicePrinted) {
                    this.invoicePrintDesc = "Reprint";
                }
                else {
                    this.invoicePrintDesc = "Print";
                }
            });

            this.ApplicationOnHold.get({
                applicationId: this.applicationId
            }, (result) => {
                this.isOnHold = result.onHold;

                this.$rootScope.$broadcast('changedOnHoldStatus', { onHold: this.isOnHold });     
            })
        }

        if (!user.isApplicant) {


            this.ApplicationOnAllowPaperReference.get({
                applicationId: this.applicationId
            }, (result) => {
                this.isOnAllowPaperReference = result.onAllowPaperReference
            })
        }
    }

    printInvoice() {
        this.PrintInvoiceForm.printInvoiceUrl({ applicationId: this.applicationId }, (result) => {
            if (result.isInvoiceExists) {
                this.invoicePrinted();
                this.invoiceURL = result.url;
                this.$window.open(result.url, '_blank');
            }
            else {
                this.dialogs.error('Print Invoice', 'There is no invoice to print.')
            }
        });
    }

    invoicePrinted() {
        if (!this.isInvoicePrinted) {
            this.ApplicationPlacement.invoicePrinted({
                applicationId: this.applicationId
            }, (result) => {
                this.invoicePrintDesc = "Reprint";
            });
        }
    }

    placementLetterPrinted() {

        this.PlacementLetter.signedURL({
            applicationIds: [this.applicationId]
        }, (result) => {
            this.placementPackURL = result.url;

            this.$window.open(result.url, '_blank');
        })

    }

    getAcceptApplicationDetails() {
        if (!this.isApplicationAccepted) {
            this.ReviewApplication.canAcceptApplication({ applicationId: this.applicationId }, (result) => {
                this.canApplicationAccept = (result && result.length == 0);
                this.applicationStatusSummaryList = result;
            })
        }
    }

    getReadmitApplicationDetails() {
        this.Readmit.canReadmit({ applicationId: this.applicationId }, (data) => {
            this.canReadmit = data.result;
            this.readmitSummaryList = data.errors;
        })
    }
    getReinstateApplicationDetails() {
        this.Reinstate.canReinstate({ applicationId: this.applicationId }, (data) => {
            this.canReinstate = data.result;
            this.reinstateSummaryList = data.errors;
        })
    }
    getIsAccountLocked() {
        this.isLocked = this.LockedStatus.isLockedOut({ userId: this.applicantUserId }, (result) => {
            this.isLocked = result.isLockedOut;
        });
    }

    acceptApplication() {           
        if (!this.isApplicationAccepted && this.isApplicationStatusAWA && this.canApplicationAccept) {
            var user = this.Auth.user;

            var data = {
                applicationId: this.applicationId,
                userId: user.userId,
                author: user.firstName + ' ' + user.lastName
            };

            this.AcceptApplication.save({ applicationId: this.applicationId }, data)
                .$promise
                .then(() => {
                    window.location.reload();
                }, () => {
                    this.dialogs.error('Internal error', 'Error occured while accepting application. Please try again after a while.')
                })
        }
    }

    checkPermission(actionName?) {

        var user = this.Auth.user
        var canAccess = false
        var interviewedByUser = user.roleDetails.contactId == this.applicationInstance.interviewerId

        if ((interviewedByUser) && (actionName == ActionName.ALLOW_PAPER_REFERENCE || actionName == ActionName.ARCHIVE || actionName == ActionName.SCHEDULE_INTERVIEW)) {
            canAccess = true
        }
        if ((user.isBranchManager) && (actionName == ActionName.ASSIGN_INTERVIEWER || actionName == ActionName.ARCHIVE)) {
            canAccess = true
        }
        else if (user.isAgent && (actionName == ActionName.ASSIGN_INTERVIEWER || actionName == ActionName.ALLOW_PAPER_REFERENCE || actionName == ActionName.ARCHIVE || actionName == ActionName.HEALTH_STATUS)) {
            canAccess = true
        }
        if (actionName == ActionName.ITEP && this.applicationInstance.iTepRequired) {
            canAccess = true
        }

        return canAccess
    }

    isInterviewReportApproved(): boolean {
        return false
    }

    changeSuppressInsurance() {
        this.ApplicationFinanceDetails.update({ applicationId: this.applicationId }, this.applicationFinanceDetails)
            .$promise
            .then(() => {
                this.$rootScope.$broadcast('suppressInsuranceChanged');
            }, () => {
                    this.applicationFinanceDetails.suppressInsurance = !this.applicationFinanceDetails.suppressInsurance
                    this.dialogs.error('Internal error', 'Error occured while suppressing insurance. Please try again after a while.')
            })
    }

    changeInfantQualified() {
        this.ApplicationInfantQualified
            .update({
                applicationId: this.applicationId,
                infantQualified: this.isInfantQualified
            })
            .$promise
            .then(() => {
            }, () => {
                this.isInfantQualified = !this.isInfantQualified
                this.dialogs.error('Internal error', 'Error occured while saving your infant qualified selection. Please try again after a while.')
            })
    }

    changeCompletionCertificate() {
        var user = this.Auth.user;
        this.ApplicationCertificate
            .update({
                applicationId: this.applicationId,
                isSuppressed: this.isCertificateSuppressed,
                author: user.firstName + ' ' + user.lastName
            })
            .$promise
            .then(() => {
            }, () => {
                this.isCertificateSuppressed = !this.isCertificateSuppressed
                this.dialogs.error('Internal error', 'Error occured while saving your Certificate supression selection. Please try again after a while.')
            })
    }

    changeInfantQualifiedOlderKids() {
        this.ApplicationIQOlderKids
            .update({
                applicationId: this.applicationId,
                infantQualifiedForOlderKids: this.isInfantQualifiedOlderKids
            })
            .$promise
            .then(() => {
            }, () => {
                this.isInfantQualifiedOlderKids = !this.isInfantQualifiedOlderKids
                this.dialogs.error('Internal error', 'Error occured while saving your infant qualified (Older kids) selection. Please try again after a while.')
            })
    }

    changeOnHold() {
        this.ApplicationOnHold
            .update({
                applicationId: this.applicationId,
                onHold: this.isOnHold,
                author: this.Auth.user.firstName + ' ' + this.Auth.user.lastName
            })
            .$promise
            .then(() => {
                this.getAcceptApplicationDetails();
                this.$rootScope.$broadcast('changedOnHoldStatus', { onHold: this.isOnHold });   
                this.$rootScope.$broadcast('applicationCommentsChange', null);
            }, () => {
                this.isOnHold = !this.isOnHold
                this.dialogs.error('Internal error', 'Error occured while saving your on hold selection. Please try again after a while.')
            })
    }

    changeUnarchive() {
        if (this.canUnarchive) {
            this.dialogs.confirm('Please Confirm', 'Are you sure that you wish to unarchive this applicant?')
                .result
                .then(() => {
                    this.ApplicationStatus.unarchive({
                        applicationId: this.applicationId
                    })
                        .$promise
                        .then(() => {
                            this.canUnarchive = false;                              
                        });
                });
        }
    }

    changeOnAllowPaperReference() {
        this.ApplicationOnAllowPaperReference
            .update({
                applicationId: this.applicationId,
                onAllowPaperReference: this.isOnAllowPaperReference,
                author: this.Auth.user.firstName + ' ' + this.Auth.user.lastName
            })
            .$promise
            .then(() => {
            }, () => {
                this.isOnAllowPaperReference = !this.isOnAllowPaperReference
                this.dialogs.error('Internal error', 'Error occured while saving your on allow paper reference selection. Please try again after a while.')
            })
    }

    changeRightFirstTime() {
        this.ApplicationRightFirstTime
            .update({
                applicationId: this.applicationId,
                rightFirstTime: this.rightFirstTime
            })
            .$promise
            .then(() => {
            }, () => {
                this.rightFirstTime = !this.rightFirstTime
                this.dialogs.error('Internal error', 'Error occured while saving right first time flag. Please try again after a while.')
            })
    }

    scheduleInterview() {
        if (!this.canScheduleInterview) {
            return this.dialogs
                .notify('Schedule Interview', 'Interview already exists')
                .result;
        }

        let interviewerId = {
            agencyId: this.Auth.user.roleDetails.agencyId,
            userId: this.Auth.user.userId,
            interviewerId: this.Auth.user.roleDetails.contactId,
            dateStart: moment().startOf('day').add(36, 'hours').toDate()
        }

        let action = new ScheduleInterview(this.$uibModal, interviewerId, this.applicationInstance.userId);
        return action
            .execute()
            .then(() => {
                //this.refresh();
            });
    }

    healthStatus() {
        if (this.healthStatus && this.canDisplayHealthStatus) {
            let healthInfo = {
                applicationId: this.applicationId,
                isOfficer: this.Auth.user.isOfficer ?? false,
                agencyId: this.Auth.user.roleDetails.agencyId,
                userId: this.Auth.user.userId,
                isAgent: this.Auth.user.isAgent ?? false
            }

            let action = new HealthStatus(this.$uibModal, healthInfo, this.applicationInstance.userId);
            return action
                .execute()
                .then(() => {
                    this.$rootScope.$broadcast('applicationCommentsChange', null);
                });
        }
    }


    assignIsDisabled = () => !this.canAssignToInterviewer;

    assign() {
        if (this.assignIsDisabled()) {
            return this.dialogs
                .notify('Assign to interviewer', 'Applicants can only be assigned at REG and OLA status')
                .result;
        }

        var action = new AssignToInterviwer(this.$uibModal, this.$scope, this.Interviewer, [this.applicationId], this.applicationAgencyId, this.applicationInterviewerId)
        action.execute();
    }

    changeArchive() {
        if (this.canArchive) {
            var action = new ArchiveApplicant(this.$uibModal, this.$scope, this.applicationId)
            action.execute();
        }
    }

    agency() {
        var action = new AssignToAgency(this.$uibModal, this.dialogs, this.Auth, [this.applicationId], this.applicationAgencyId, this.applicationInterviewerId)
        action.execute()
    }

    sendBackToAgency() {
        var action = new SendBackToAgency(this.$uibModal, this.applicationId)
        action.execute()
    }

    assignCPI() {
        var action = new AssignCPI(this.$uibModal, this.applicationId, this.cpiStatusId)
        action.execute()
    }

    setFlightDate() {
        var action = new setFlightDates(this.$uibModal, this.$scope, this.applicationId, this.earliestFlightDate, this.latestFlightDate)
        action.execute()
    }

    setTXPDate() {
        var action = new setTXPDates(this.$uibModal, this.$scope, this.applicationId, this.txpDate)
        action.execute()
    }

    readmit() {
        if (this.canReadmit) {
            var action = new Readmit(this.$uibModal, this.$scope, this.applicationId)
            action.execute()
        }
    }

    reinstate() {
        if (this.canReinstate) {
            var action = new Reinstate(this.$uibModal, this.$scope, this.applicationId)
            action.execute()
        }
    }

    unlock() {
        if (this.isLocked) {
            this.LockedStatus.unlock({ userId: this.applicantUserId }, () => {
                this.isLocked = false;
            });
        }
    }

    cancelApplication() {
        var user = this.Auth.user;
        //removing hasNotesCompleted hasChangesToApprove and isBalanceClear condition because business(office user) wanted to cancel anyways.
        if (user.isOfficer) {
            var action = new CancelApplication(this.$uibModal, this.Auth, this.$scope, this.applicationId);
            action.execute();
        }
    }

    failApplication() {
        var action = new FailApplication(this.$uibModal, this.Auth, this.$scope, this.applicationId);
        action.execute();

    }   

    showITep() {
        var action = new ITep(this.$uibModal, this.$scope, this.applicationId);
        action.execute();
    }
}

angular
    .module('app')
    .controller('ApplicationController', ApplicationController)