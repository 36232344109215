import moment from 'moment';
import { SectionController } from '../SectionController';
import { ReviewPage } from '../../../services/ReviewService';
import { ItemGroupId } from '../../../models/ItemGroup';
import { ReviewItemId } from '../../../models/ReviewItem';

interface InterviewReportScope extends angular.IScope {
    interviewReportForm: any;
}

class Section {
    note: any = {}
}

class InterviewReportController extends SectionController {       

    displayNote_read: boolean;
    displayNote_edit: boolean;

    displayApproveButton: boolean;

    isTheInterviewer: boolean;
    isTheAgent: boolean
    isOfficeUser: boolean;

    status: string;
    page: ReviewPage;
    maxDate: Date;

    isEditForm: { }
    
    static override $inject = ['$rootScope', '$scope', '$q', '$interval', '$uibModal', 'dialogs', 'InterviewReport', 'interviewReport', 'agencyId', 'interviewerId', 'applicationId', 'review', 'ApproveChanges', 'Auth', 'ReevaluateSection', 'applicationObservable'];
    constructor(
        $rootScope: angular.IRootScopeService,
        $scope: InterviewReportScope,
        private $q: ng.IQService,
        private $interval: ng.IIntervalService,
        private $uibModal,
        dialogs,
        private InterviewReport,
        public interviewReport,
        private agencyId,
        private interviewerId,
        applicationId,
        review,
        private ApproveChanges,
        Auth,
        ReevaluateSection,
        applicationObservable
        ) {
        super($rootScope, $scope, Auth, ReevaluateSection, review, applicationId, ItemGroupId.InterviewReport, applicationObservable, dialogs);

        var sections = ['about', 'english', 'childcare', 'motivation', 'personality', 'evaluation', 'returner', 'medical', 'referral', 'declaration'];
        this.maxDate = new Date();

        this.isOfficeUser = Auth.user.isOfficer;

        let hasNote = false;
        for (var i = 0; i < review.pages.length; i++) {
            if (review.pages[i].menuItem.name == "Interview Report") {
                hasNote = review.pages[i].hasNote;
            }
        }     

        for (let section of sections) {
            if (!this.interviewReport[section]) {
                this.interviewReport[section] = new Section();
            }
        }


        applicationObservable.subscribe(applicationInstance => {
            if (!this.interviewReport.applicationId) this.interviewReport.applicationId = applicationId;
            if (!this.interviewReport.interviewerId) this.interviewReport.interviewerId = applicationInstance.interviewerId;
            if (!this.interviewReport.agencyId) this.interviewReport.agencyId = applicationInstance.agencyId;

            this.status = applicationInstance.status;

            this.isTheInterviewer = this.Auth.user.roleDetails.contactId === applicationInstance.interviewerId;
            this.isTheAgent = this.Auth.user.isAgent && this.interviewReport.agencyId === this.Auth.user.roleDetails.agencyId;


            this.displayNote_read =
                (this.status == "OLA" && this.isTheInterviewer) ||
                ((this.status == "AWA" || this.status == "TBM") && (this.isTheInterviewer || this.isTheAgent));

            this.displayNote_edit = !this.displayNote_read && ((this.isTheAgent && this.status == "OLA") || this.isOfficeUser) ;

            if (this.status !== "OLA") {
                this.setupMetaformVersion();
            }


            let forms = [
                'InterviewReport_AboutTheInterview',
                'InterviewReport_EnglishSkills',
                'InterviewReport_ChildcareExperience',
                'InterviewReport_Motivation',
                'InterviewReport_MedicalCriminal',
                'InterviewReport_Personality',
                'InterviewReport_Evaluation',
                'InterviewReport_Declaration'
            ];
            if (!this.isEditForm) {
                this.isEditForm = {};
                for (let form of forms) {
                    this.isEditForm[form] = this.editForm(form);
                }
            }

        });
        

        this.page = review.getPage(ItemGroupId.InterviewReport);
        this.displayApproveButton = this.page && this.page.approveChange && this.isOfficeUser;

        // ALL SECTIONS need to be added here
        // DO NOT USE interviewReportForm.$dirty directly as notes are part of main form and they trigger to show save button
        $scope.$watchGroup([
            'interviewReportForm.InterviewReportAbout.$dirty',
            'interviewReportForm.InterviewReportEnglishSkills.$dirty',
            'interviewReportForm.InterviewReportChildcareExperience.$dirty',
            'interviewReportForm.InterviewReportMotivation.$dirty',
            'interviewReportForm.InterviewReportMedicalCriminal.$dirty',
            'interviewReportForm.InterviewReportPersonality.$dirty',
            'interviewReportForm.InterviewReportEvaluation.$dirty',
            //'interviewReportForm.InterviewReportPreviousExperience.$dirty',
            //'interviewReportForm.InterviewReportAmbassadorReferral.$dirty',
            //'interviewReportForm.InterviewReportSkypeInterview.$dirty',
            'interviewReportForm.InterviewReportDeclaration.$dirty',

        ], (newValues, oldValues) => {
            this.$dirty = newValues.includes(true);
        });

        if (this.interviewReport) {
            var todayDate = moment();
            this.interviewReport.about.todaydate = function () {
                return todayDate;
            }
            var yesterdayDate = moment().add(-1, "days");
            this.interviewReport.about.yesterdayDate = function () {
                return yesterdayDate;
            }
        }

    }

    editForm(type: string) {
        // Special case for Motivation 
        // Editable by Interviewer, Agency User and Office User at OLA ONLY once AP is AWA or any status post AWA this is ONLY editable by Office User
        // If there is a sticky note on the motivation & Expectations section, its still NOT editable by Interviewer and Agency User. 
        if (type == 'InterviewReport_Motivation') {

            if (this.isOfficeUser) {
                if (this.status == 'OLA' || this.status == "AWA" || this.status == "TBM" || this.status == "PLC") {
                    this.interviewReport.motivation.disableFirstPart = true;
                    this.interviewReport.motivation.disableLastPart = false;
                } else {
                    this.interviewReport.motivation.disableFirstPart = true;
                    this.interviewReport.motivation.disableLastPart = true;
                }
            } else if (this.isTheInterviewer || this.isTheAgent) {

                if (this.status == 'OLA') {
                    this.interviewReport.motivation.disableFirstPart = false;
                    this.interviewReport.motivation.disableLastPart = false;
                } else if ((this.status == "AWA" || this.status == "TBM") && this.hasNote(type)) {
                    this.interviewReport.motivation.disableFirstPart = false;
                    this.interviewReport.motivation.disableLastPart = true;
                } else {
                    this.interviewReport.motivation.disableFirstPart = true;
                    this.interviewReport.motivation.disableLastPart = true;
                }
            }
            return !this.interviewReport.motivation.disableFirstPart || !this.interviewReport.motivation.disableLastPart;
        }
        
        if (this.isTheInterviewer || this.isTheAgent) {
            // Editable for Interviewer and Agency User at OLA
            if (this.status == "OLA") return true;


            // If there is a sticky note on a section at AWA or TBM then that section only is editable for Interviewer or Agency User
            if ((this.status == "AWA" || this.status == "TBM") && this.hasNote(type)) {
                return true;
            }
        }

        // special case for AboutTheInterview
        // If the form is not editable check special case for MetInPersonDate field
        if (type == 'InterviewReport_AboutTheInterview') {

            let editOnlyMetInPersonDate =
                (this.isTheInterviewer || this.isTheAgent || this.isOfficeUser) &&
                this.status !== "INA" &&
                this.status !== "AWL" &&
                this.status !== "APD" &&
                this.status !== "REG";

            this.interviewReport.about.editOnlyMetInPersonDate = editOnlyMetInPersonDate

            return editOnlyMetInPersonDate;
        }

        return false;
    }

    hasNote(type: string) {
        var typeId = ReviewItemId[type]
        var reviewNote = this.review.getReviewableItemNote(typeId);
        return reviewNote && reviewNote.note && reviewNote.note.status == 0
    }

    setupMetaformVersion() {
        this.interviewReport.about.requesFormVer = this.getMetaformVersion('InterviewReportAbout');
        this.interviewReport.childcare.requesFormVer = this.getMetaformVersion('InterviewReportChildcareExperience');
        this.interviewReport.personality.requesFormVer = this.getMetaformVersion('InterviewReportPersonality');
        this.interviewReport.motivation.requesFormVer = this.getMetaformVersion('InterviewReportMotivation');
        this.interviewReport.english.requesFormVer = this.getMetaformVersion('InterviewReportEnglishSkills');
        this.interviewReport.medical.requesFormVer = this.getMetaformVersion('InterviewReportMedicalCriminal');
        this.interviewReport.evaluation.requesFormVer = this.getMetaformVersion('InterviewReportEvaluation');
        this.interviewReport.declaration.requesFormVer = this.getMetaformVersion('InterviewReportDeclaration');
    }

    getMetaformVersion(name) {
        var item = this.page.items.find(i => i.menuItem.snapshotFormName === name)
        return item ? item.metaformVersion : 0;
    }

    save() {
        this.$scope.$broadcast('submitted', true);

        var promisses = [];
        var form = this.$scope.interviewReportForm;
        if (form.$dirty) {

            if (form['InterviewReportAbout']) this.interviewReport.about.metaform = form['InterviewReportAbout'].$metaformVersion;
            if (form['InterviewReportChildcareExperience']) this.interviewReport.childcare.metaform = form['InterviewReportChildcareExperience'].$metaformVersion;
            if (form['InterviewReportPersonality']) this.interviewReport.personality.metaform = form['InterviewReportPersonality'].$metaformVersion;
            if (form['InterviewReportMotivation']) this.interviewReport.motivation.metaform = form['InterviewReportMotivation'].$metaformVersion;
            if (form['InterviewReportEnglishSkills']) this.interviewReport.english.metaform = form['InterviewReportEnglishSkills'].$metaformVersion;
            if (form['InterviewReportMedicalCriminal']) this.interviewReport.medical.metaform = form['InterviewReportMedicalCriminal'].$metaformVersion;
            if (form['InterviewReportEvaluation']) this.interviewReport.evaluation.metaform = form['InterviewReportEvaluation'].$metaformVersion;
            if (form['InterviewReportDeclaration']) this.interviewReport.declaration.metaform = form['InterviewReportDeclaration'].$metaformVersion;

            //this.interviewReport.referral.metaform = form['InterviewReportAmbassadorReferral'].$metaformVersion;

            promisses.push(
                this.InterviewReport.update(this.interviewReport).$promise);
        }

        this.$q.all(promisses)
            .then(() => {
                form.$setPristine();
                //TODO move this to parent class and remove saved flag
                this.alerts.push({ // TODO should I be able to display the same message twice?
                    type: 'success',
                    msg: 'Save complete',
                    dismissOnTimeout: 5000
                })
            }, () => {
                //TODO move this to parent class and remove saved flag
                this.alerts.push({ // TODO should I be able to display the same message twice?
                    type: 'danger',
                    msg: 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:support@aupairamerica.co.uk" > support@aupairamerica.co.uk</a>',
                    dismissOnTimeout: 20000
                })
            });
    }

    changesApproved() {

        var user = this.Auth.user
        var data = {
            applicationId: this.applicationId,
            item: ItemGroupId.InterviewReport,
            author: user.firstName + ' ' + user.lastName
        };

        this.ApproveChanges
            .update({ applicationId: this.applicationId }, data)
            .$promise
            .then(data => {
                if (this.displayApproveButton === true) {
                    this.displayApproveButton = false;
                    this.page.approveChange = false;
                }

                this.alerts.push({
                    type: 'success',
                    msg: 'Approval complete',
                    dismissOnTimeout: 5000
                });
            }, () => {
                this.alerts.push({
                    type: 'danger',
                    msg: 'We are sorry but this form could not be approved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:support@aupairamerica.co.uk" > support@aupairamerica.co.uk</a>',
                    dismissOnTimeout: 20000
                })
            });
    }
}

angular
    .module('app')
    .controller('InterviewReportController', InterviewReportController);