
class ReferenceController {
        
    saved: boolean;


    static $inject = ['$scope', '$q', 'dialogs', 'HelpTexts'];
    constructor(private $scope, private $q, private dialogs, private HelpTexts) {

    }

    //save() {
    //    this.$scope.$broadcast('submitted', true);

    //    var promisses = [];
    //    var form = this.$scope.interviewReportForm;
    //    if (form['Program'].$dirty) {
    //        promisses.push(
    //            this.HelpTexts.update(this.program, { module: 'Program' }).$promise);
    //    }
    //    if (form['PreMatch'].$dirty) {
    //        promisses.push(
    //            this.HelpTexts.update(this.prematch, { module: 'PreMatch' }).$promise);
    //    }
    //    if (form['Passport'].$dirty) {
    //        promisses.push(
    //            this.HelpTexts.update(this.passport, { module: 'Passport' }).$promise);
    //    }
    //    if (form['Visa'].$dirty) {
    //        promisses.push(
    //            this.HelpTexts.update(this.visa, { module: 'Visa' }).$promise);
    //    }

    //    this.$q.all(promisses)
    //        .then(() => {
    //            form.$setPristine();
    //            $('html, body').animate({ scrollTop: 860 }, 200);
    //            this.saved = true;
    //        }, () => {
    //            this.dialogs.error('Error', 'We are sorry but your form could not be saved. Please try again in 5 minutes. If this problem continues, please contact us at: <a target="_blank" href="mailto:support@aupairamerica.co.uk">support@aupairamerica.co.uk<a>');
    //        });
    //}
}

angular
    .module('app')
    .controller('HelpText.ReferenceController', ReferenceController);