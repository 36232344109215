import { SectionController } from '../../SectionController';
import { ItemGroupId } from '../../../../models/ItemGroup';
import { ReferenceModule } from '../../../reference/ReferenceModule';


enum ReferenceType { Unknown, Character = 1, Educare, Childcare, PreviousHostFamily }
enum ReferenceStatus { ReferenceReviewed = 6, Approve = 8 }


class ReferenceInstanceController extends SectionController {

    form
    isOfficerUser: boolean = false;
    canInterviewerEditTelRefCheck: boolean = false;

    assignId: number;
    stickyNodeMode: string;

    static override $inject = ['$rootScope', '$scope', 'dialogs', 'Reference', 'reference', 'TelephoneReferenceCheck', 'telephoneReferenceCheck', 'dce', '$window', 'ApplicationDocument', 'Auth', 'ReevaluateSection', 'review', 'applicationId', 'applicationObservable'];
    constructor($rootScope, $scope, dialogs, private Reference, public reference, private TelephoneReferenceCheck, public telephoneReferenceCheck, public dce, private $window, private ApplicationDocument, Auth, ReevaluateSection, review, applicationId, applicationObservable) {
        super($rootScope, $scope, Auth, ReevaluateSection, review, applicationId, ItemGroupId.References, applicationObservable, dialogs);
        this.loadDocuments();
        if (Auth.user.isOfficer) {
            this.isOfficerUser = Auth.user.isOfficer;
        }             

        applicationObservable.subscribe(applicationInstance => {
            if ((applicationInstance.interviewerId > 0 && applicationInstance.interviewerId === Auth.user.roleDetails.contactId)
                && !this.telephoneReferenceCheck.isApproved) {
                this.canInterviewerEditTelRefCheck = true;
            }

            if ((Auth.user.isAgent || Auth.user.isOfficer) && applicationInstance.interviewerId != Auth.user.roleDetails.contactId) {
                this.stickyNodeMode = 'edit';
            }
        })

            
            
    }

    private loadDocuments() {
        var referenceCategoryTypeId;

        //3: Childcare, 1: Character, 4: Previous Host Family, 2: Educare
        if (this.reference.referenceType == 3) {
            referenceCategoryTypeId = 13;
        }
        else if (this.reference.referenceType == 1) {
            referenceCategoryTypeId = 14;
        }
        else if (this.reference.referenceType == 4) {
            referenceCategoryTypeId = 15;
        }
        else if (this.reference.referenceType == 2) {
            referenceCategoryTypeId = 16;
        }

        return this.ApplicationDocument.fetchUploadedDocumentsForReference({
            applicationId: this.reference.applicationId,
            referenceId: this.reference.id,
            categoryId: referenceCategoryTypeId
        }, (document) => {
            var docu1 = document[0];
            if (docu1.documents.length > 0) {
                this.reference.documentImages1 = docu1.documents[0].pages;
            }

            var docu2 = document[1];
            if (docu2.documents.length > 0) {
                this.reference.documentImages2 = docu2.documents[0].pages;
            }

        }).$promise
    }

    openUrlInNewWindow(uploadedDocURL) {
        this.$window.open(uploadedDocURL);
    }

    private referenceTypeName() {
        switch (this.referenceType()) {
            case ReferenceType.Character:
                return 'Character reference';
            case ReferenceType.Childcare:
                return 'Childcare reference';
            case ReferenceType.Educare:
                return 'Educare reference';
            case ReferenceType.PreviousHostFamily:
                return 'Previous host family reference';
            default:
                return 'Reference';
        }
    }

    private referenceType(): ReferenceType {
        if (!this.reference)
            return ReferenceType.Unknown

        return <ReferenceType>this.reference.referenceType
    }

    isCharacter = () => this.referenceType() === ReferenceType.Character
    isEducare = () => this.referenceType() === ReferenceType.Educare
    isChildcare = () => this.referenceType() === ReferenceType.Childcare
    isFamily = () => this.referenceType() === ReferenceType.PreviousHostFamily

    redirectToReferencePage(response) {
        if (response) {
            angular.extend(this.telephoneReferenceCheck, response)
        }

        var url = this.$window.location.href;
        this.$window.location.href = url.substr(0, url.lastIndexOf("/"));
    }

    approveUnapproveReference(isApprove: boolean) {
        var referenceStatus = ReferenceStatus.ReferenceReviewed;

        if (isApprove) {
            referenceStatus = ReferenceStatus.Approve;
        }

        this.Reference.updateReferenceStatus({ id: this.reference.id, status: referenceStatus }).$promise.then((response) => {
            this.redirectToReferencePage(null);
        });
    }

    saveTelephoneReferenceCheckAndTranslation() {
        this.$scope.$broadcast('submitted', true)

        if (this.telephoneReferenceCheck.$validation()) {
            //var confirmRequest = this.dialogs.confirm('Complete Reference Check', 'Are you sure that you have completed this telephone reference check correctly?  You will be unable to edit these values unless problems are found by your agency or the Au Pair head office.');

            //confirmRequest.result.then(() => {  
                //put next lines in here if need message again
            //});

            this.TelephoneReferenceCheck.save(this.telephoneReferenceCheck).$promise.then((response) => {
                if (this.reference.wantToTranslate && !this.reference.IsTranslated) {
                    this.Reference.saveTransaction(this.reference.referenceTranslation).$promise.then(() => {
                        this.redirectToReferencePage(response);
                    }, () => {
                        this.dialogs.error('Error', 'We are sorry but your form could not be saved. Please try again in 5 minutes. If this problem continues, please contact us at: <a target="_blank" href="mailto:support@aupairamerica.co.uk">support@aupairamerica.co.uk<a>')
                    });
                }
                else {
                    this.redirectToReferencePage(response);
                }
            });

        }
    }
}

ReferenceModule
    .instance
    .controller('ReferenceInstanceController', ReferenceInstanceController)

