
class VisaDetailsEditController {
    visaDetails: any;
    showSuccessText: boolean = false;

    isOnlineOrAwaitingOrToBeMatch: boolean;
    showVisaDetails: boolean;

    static $inject = ['$scope', 'ApplicationPlacement', 'dialogs', '$timeout', 'applicationObservable', 'applicationId']
    constructor(private $scope, private ApplicationPlacement, private dialogs, private $timeout, applicationObservable, private applicationId) {

        applicationObservable.subscribe(applicationInstance => {
            this.isOnlineOrAwaitingOrToBeMatch = (applicationInstance.status === 'OLA' || applicationInstance.status === 'AWA' || applicationInstance.status === 'TBM');
            this.showVisaDetails = (applicationInstance.status === 'PLC' || applicationInstance.status === 'ACT' || applicationInstance.status === 'REM' || applicationInstance.status === 'INA' || applicationInstance.status === 'APD' || applicationInstance.status === 'AWL');

            if (this.showVisaDetails) {
                this.visaDetails = this.ApplicationPlacement.visaDetails({
                    applicationId: this.applicationId
                });
            }
        })
    }

    save() {
        this.$scope.$broadcast('submitted', true);
        var form = this.$scope.visaDetailsForm;
        if (form.$dirty) {
            this.ApplicationPlacement.saveVisaDetails({
                applicationId: this.applicationId
            }, this.visaDetails)
                .$promise
                .then(() => {
                    form.$setPristine();
                    $('html, body').animate({ scrollTop: 0 }, 200);
                    this.showSuccessText = true;
                    this.$timeout(() => { this.showSuccessText = false; }, 3000);
                    if (!this.visaDetails.embassyAppointmentDate) {
                        this.visaDetails = this.ApplicationPlacement.visaDetails({
                            applicationId: this.applicationId
                        });
                    }
                }, () => {
                    this.dialogs.error('Error', 'We are sorry but your form could not be saved. Please try again in 5 minutes. If this problem continues, please contact us at: <a target="_blank" href="mailto:support@aupairamerica.co.uk">support@aupairamerica.co.uk<a>');
                });
        }
    }
}

angular
    .module('app')
    .controller('VisaDetailsEditController', VisaDetailsEditController);