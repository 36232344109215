
class TravelAndProgramController {

    saved: boolean;

    static $inject = ['$scope', '$q', 'dialogs', 'HelpTextsAdmin', 'program', 'prematch', 'passport', 'visa'];
    constructor(private $scope, private $q, private dialogs, private HelpTextsAdmin, private program, private prematch, private passport, private visa) {

    }

    save() {
        this.$scope.$broadcast('submitted', true);

        var promisses = [];
        var form = this.$scope.interviewReportForm;
        if (form['Program'].$dirty) {
            promisses.push(
                this.HelpTextsAdmin.save({ module: 'Program' }, this.program).$promise);
        }
        if (form['PreMatch'].$dirty) {
            promisses.push(
                this.HelpTextsAdmin.save({ module: 'PreMatch' }, this.prematch).$promise);
        }
        if (form['Passport'].$dirty) {
            promisses.push(
                this.HelpTextsAdmin.save({ module: 'Passport' }, this.passport).$promise);
        }
        if (form['Visa'].$dirty) {
            promisses.push(
                this.HelpTextsAdmin.save({ module: 'Visa' }, this.visa).$promise);
        }

        this.$q.all(promisses)
            .then(() => {
                form.$setPristine();
                $('html, body').animate({ scrollTop: 860 }, 200);
                this.saved = true;
            }, () => {
                this.dialogs.error('Error', 'We are sorry but your form could not be saved. Please try again in 5 minutes. If this problem continues, please contact us at: <a target="_blank" href="mailto:support@aupairamerica.co.uk">support@aupairamerica.co.uk<a>');
            });
    }
}

angular
    .module('app')
    .controller('HelpText.TravelAndProgramController', TravelAndProgramController);