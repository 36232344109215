
class VisaDetailsController {
    visaDetails: any;
    showSuccessText: boolean = false;

    isOnlineOrAwaitingOrToBeMatch: boolean;
    showVisaDetails: boolean;

    hasBeenPlaced: boolean = false;

    static $inject = ['$scope', '$q', 'Auth', 'ApplicationPlacement', 'dialogs', '$timeout', 'applicationObservable', 'applicationId', 'applicationStatusHistory']
    constructor(private $scope, private $q, private Auth, private ApplicationPlacement, private dialogs, private $timeout, applicationObservable, private applicationId, private applicationStatusHistory) {

        for (let change of applicationStatusHistory) {
            if (change.newStatusCode === 'PLC') {
                this.hasBeenPlaced = true;
            }
        }

        applicationObservable.subscribe(applicationInstance => {
            let status = applicationInstance.status;

            this.isOnlineOrAwaitingOrToBeMatch = status === 'OLA' || status === 'AWA' || status === 'TBM';

            this.showVisaDetails = status === 'PLC' || status === 'ACT' || status === 'INA' || status === 'REM' || status === 'TBM';
            this.showVisaDetails = this.hasBeenPlaced && this.showVisaDetails;

            if (this.showVisaDetails) {
                this.visaDetails = this.ApplicationPlacement.visaDetails({ applicationId: this.applicationId });
            }
        })
    }

    readonly() {
        return !this.Auth.user.isOfficer && (this.visaDetails.intendToReapply === true || this.visaDetails.intendToReapply === false);
    }

    save() {
        let promisses = [];
        let forms = this.$scope.visaForm;
        this.$scope.$broadcast('submitted', true);
        if (forms.visaDetailsForm.$dirty) {
            let promise = this.ApplicationPlacement
                .saveVisaDetails({ applicationId: this.applicationId }, this.visaDetails)
                .$promise
                .then(() => {
                    forms.visaDetailsForm.$setPristine();
                    if (!this.visaDetails.embassyAppointmentDate) {
                        this.visaDetails = this.ApplicationPlacement.visaDetails({
                            applicationId: this.applicationId
                        });
                    }
                });
            promisses.push(promise);
        }

        this.$q.all(promisses)
            .then(() => {
                $('html, body').animate({ scrollTop: 0 }, 200);
                forms.$setPristine();
                this.showSuccessText = true;
                this.$timeout(() => { this.showSuccessText = false; }, 3000);
            }, () => {
                this.dialogs.error('Error', 'We are sorry but your form could not be saved. Please try again in 5 minutes. If this problem continues, please contact us at: <a target="_blank" href="mailto:support@aupairamerica.co.uk">support@aupairamerica.co.uk<a>');
            })
    }
}

angular
    .module('app')
    .controller('VisaDetailsController', VisaDetailsController);