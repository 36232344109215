 
import { bootstrap } from 'angular';
import _ from 'lodash-es'
import { allowTypes, ChangeDocumentTypeController } from '../../changeDocumentType/ChangeDocumentTypeController';


const mapStatusToProgress = {
    'upload': 'info',
    'success': 'success',
    'faild': 'danger'
}

class Item {

    file;
    status;

    constructor(private $window, attr) {
        angular.extend(this, attr);
    }

    isImage() {
        if (!angular.isObject(this.file) || !(this.file instanceof this.$window.File))
            return false;
        var type = '|' + this.file.type.slice(this.file.type.lastIndexOf('/') + 1) + '|';
        return '|apng|png|jpg|jpeg|jfif|pjpeg|pjp|bmp|gif|svg|tif|tiff|webp|avif'.indexOf(type) !== -1;
    }

    isVideo() {
        if (!angular.isObject(this.file) || !(this.file instanceof this.$window.File))
            return false;
        var type = '|' + this.file.type.slice(this.file.type.lastIndexOf('/') + 1) + '|';
        return '|m4v|3gp|nsv|ts|ty|strm|rm|rmvb|m3u|ifo|mov|qt|divx|xvid|bivx|vob|nrg|img|iso|pva|wmv|asf|asx|ogm|m2v|avi|bin|dat|dvr-ms|mpg|mpeg|mp4|mkv|avc|vp3|svq3|nuv|viv|dv|fli|flv|wpl|'.indexOf(type) !== -1;
    }

    progressType = () => mapStatusToProgress[this.status];
}

class UploadStatus {
    static inProgress = "inProgress"
    static upload = "upload"
    static failed = "failed"
}



class DocumentDisplayModalController {

    status: UploadStatus;
    items = [];
    uploads = []; 
    allowedContentType;

    isApplicant: boolean;
    isOfficeUser: boolean;
    canDeleteDocument: boolean;
    allowDescriptionChange: boolean;
    showApproveButton: boolean;
    showChangeTypeButton: boolean;

    static $inject = ['$sce', '$rootScope', 'dialogs', '$uibModalInstance', 'ApplicationDocument', 'ApplicationDocumentPage', 'ContactDocument', 'document', 'Auth', 'onDeleteHandler', 'onUpdateHandler', 'onApproveHandler', 'documentDefinition', '$q', '$window', 'Upload', 'uploader', 'documentType', 'ContractDocument', 'applicationStatus', '$uibModal'];
    constructor($sce, private $rootScope, private dialogs, private $uibModalInstance, private ApplicationDocument, private ApplicationDocumentPage, private ContactDocument, public document, private Auth, private onDeleteHandler, private onUpdateHandler, private onApproveHandler, private documentDefinition, private $q, private $window, private Upload, private uploader, private documentType, private ContractDocument, private applicationStatus, private $uibModal) {

        this.isApplicant = Auth.user.isApplicant;
        this.isOfficeUser = Auth.user.isOfficer;

        // Office/Int/Agency user can delete a document at any time
        // Applicant can only delete if its not approved
        // Disbale description textbox if media is approved for applicant
        this.canDeleteDocument = true;
        this.showApproveButton = true;
        this.showChangeTypeButton = allowTypes.indexOf(this.document.documentType) !== -1;
        this.allowDescriptionChange = true;

        if (this.isApplicant) {
            if (applicationStatus === 'OLA' || applicationStatus === 'AWA' || applicationStatus === 'TBM') {
                if (document.documentType === 5 || document.documentType === 6 || document.documentType === 35) {
                    this.canDeleteDocument = true
                } else {
                    this.canDeleteDocument = document.status === 2
                }
            }
            else {
                this.canDeleteDocument = false
            }
            //allowDescriptionChange should be true if status is OLA and else we need to allow change according to documentStatus
            if (applicationStatus !== 'OLA') {
                this.allowDescriptionChange = document.status === 1 ? false : true;
            }
        }

        //contract local fees documents
        if (this.uploader && (document.documentType === 49 || document.documentType === 50)) {
            this.canDeleteDocument = !this.uploader.canViewOnly;
            //never show approve unapprove for contract. document is by default approved 
            this.showApproveButton = false;
        }

        if (document.documentType === 35) {
            var pages = document.pages;
            for (var i = 0; i < pages.length; i++) {
                if (angular.isString(pages[i].url)) {
                    pages[i].videoUrl = [{
                        src: $sce.trustAsResourceUrl(pages[i].url),
                        type: 'video/mp4'
                    }];
                }
            }
        }
            
        this.allowedContentType = documentDefinition.allowedContentType + '*';
    }

    removeContactPortrait() {
        this.ContactDocument.remove({ contactdocumentId: this.document.id }, {})
            .$promise
            .then(() => {
                this.$uibModalInstance.close(null);
            }, () => {
                this.dialogs.error('Internal error', 'Error occured while deleting a Portrait image. Please try again after a while.');
            });
    }

    removeAgencyContractDocumentPage(page) {
        this.dialogs.confirm('Confirm Delete', 'Are you sure that you want to delete this file?')
            .result
            .then(() => {
                this.ContractDocument.removePage({ documentFileId: page.id}, page)
                    .$promise
                    .then(() => {
                        var index = this.document.pages.indexOf(page);
                        if (index > -1) {
                            this.document.pages.splice(index, 1);
                        }
                        if (this.document.pages.length == 0) {
                            this.ContractDocument.remove({ documentId: this.document.id }, {})
                                .$promise
                                .then(() => {
                                    //if (this.onDeleteHandler) {
                                    //    this.onDeleteHandler();
                                    //}
                                    if (this.onDeleteHandler) {
                                        this.onDeleteHandler();
                                    }

                                    this.$uibModalInstance.close(null);
                                }, () => {
                                    this.dialogs.error('Internal error', 'Error occured while deleting file. Please try again after a while.');
                                });
                        }
                    })
            });
    }

    removeAllAgencyContractTerritoryDocuments() {
        this.dialogs.confirm('Confirm Delete', 'Are you sure that you want to delete this document?')
            .result
            .then(() => {
                this.ContractDocument.remove({ documentId: this.document.id }, {})
                    .$promise
                    .then(() => {

                        if (this.onDeleteHandler) {
                            this.onDeleteHandler();
                        }

                        this.$uibModalInstance.close(null);
                    }, () => {
                        this.dialogs.error('Internal error', 'Error occured while deleting documents. Please try again after a while.');
                    });
            });

    }

    remove() {
        if (this.document.agencyTerritoryId) {
            this.removeAllAgencyContractTerritoryDocuments();
            return;
        }
        this.dialogs.confirm('Confirm Delete', 'Are you sure that you want to delete this document?')
            .result
            .then(() => {
                this.ApplicationDocument.remove({ documentId: this.document.id }, {})
                    .$promise
                    .then(() => {
                        if (this.onDeleteHandler) {
                            this.onDeleteHandler();
                        }

                        this.$uibModalInstance.close(null);
                    }, () => {
                        this.dialogs.error('Internal error', 'Error occured while deleting an image. Please try again after a while.');
                    });
            });
            
    }

    updateDescription() {
        this.ApplicationDocument.update({ documentId: this.document.id }, { description: this.document.description })
            .$promise
            .then(() => {
                this.$uibModalInstance.close(this.document);
            }, () => {
                this.dialogs.error('Internal error', 'Error occured while saving. Please try again after a while.');
            });
    }

    downloadVideo() {   // download the video
        window.open(this.document.pages[0].url, '_blank', '');
    }

    approve() {   
        this.document.status = 1 // approved
        this.ApplicationDocument.approve({ documentId: this.document.id }, { status: this.document.status }) 
            .$promise
            .then((results) => {
                if (this.onApproveHandler) {
                    this.onApproveHandler(this.document.status);
                }

                this.uploadStateChanged();
                this.$uibModalInstance.close(this.document);
            }, () => {
                this.dialogs.error('Internal error', 'Error occured while approving an image. Please try again after a while.');
            });
    }

    unapprove() {
        this.document.status = 2 // awaiting approval
        this.ApplicationDocument.unapprove({ documentId: this.document.id }, { status: this.document.status })
            .$promise
            .then(() => {
                if (this.onUpdateHandler) {
                    this.onUpdateHandler(this.document.status);
                }

                this.uploadStateChanged();
                this.$uibModalInstance.close(this.document);
            }, () => {
                this.dialogs.error('Internal error', 'Error occured while unapproving an image. Please try again after a while.');
            });
    }

    uploadStateChanged() {
        this.$rootScope.$broadcast('uploadDataStateChanged', true);
    }

    removePage(page) {
        if (this.document.agencyTerritoryId) {
            this.removeAgencyContractDocumentPage(page);
            return;
        }

        this.dialogs.confirm('Confirm Delete', 'Are you sure that you want to delete this file?')
            .result
            .then(() => {
                this.ApplicationDocumentPage.remove(page)
                    .$promise
                    .then(() => {
                        var index = this.document.pages.indexOf(page);
                        if (index > -1) {
                            this.document.pages.splice(index, 1);
                        }
                        if (this.document.pages.length == 0) {
                            if (this.onDeleteHandler) {
                                this.onDeleteHandler();
                            }

                            this.$uibModalInstance.close(null);
                        }
                    })
            });
    }

    save(document) {
        this.ApplicationDocument.update({ documentId: document.id }, document)
            .$promise
            .then(() => {                   
                this.$uibModalInstance.close(document);
            }, () => {                   
                this.dialogs.error('Internal error', 'Please try again after a while.');
            });
    }

    close() {
        this.$uibModalInstance.dismiss();
    }






    fileSelected($files, $event) {
        if (!angular.isArray($files) || $files.length === 0)
            return;

        if ($files.length > this.avaliblePages()) {
            this.dialogs.error('Too many files selected', 'Too many files selected');
            return;
        }
            
        var items = this.addItems($files);
    }

    addItems(files) {
        var result = [];
        for (var i = 0; i < files.length; i++) {
            var extension = files[i].name.slice(files[i].name.lastIndexOf('.'));
            if ('|.apng|.png|.jpg|.jpeg|.jfif|.pjpeg|.pjp|.bmp|.gif|.svg|.tif|.tiff|.webp|.avif|.heic|'.indexOf('|' + extension + '|') >= 0) {
                extension = '.webp';
            }
            var item = new Item(this.$window, {
                file: files[i],
                extension: extension
            });
            result.push(item);

            this.items.push(item);
        }
        return result;
    }

    removeItem(item) {
        _.remove(this.items, item);
    }

    abort() {
        for (let item of this.uploads) {
            item.abort();
        }
    }
    uploadItems(uploadDescription) {

        var promises = this.items.filter(item => item.status !== 'success').map((item, i) => {

            let key = uploadDescription.filePattern.replace('[PAGE]', '-' + i) + item.extension;

            item.status = 'upload';
            item.progress = 0;
            var upload = this.Upload.upload({
                url: 'https://' + uploadDescription.bucket + '.s3.amazonaws.com/',
                method: 'POST',
                fields: {
                    key: key,
                    AWSAccessKeyId: uploadDescription.awsAccessKeyId,
                    acl: uploadDescription.acl,
                    policy: uploadDescription.policy,
                    signature: uploadDescription.signature,
                    'Content-Type': item.file.type
                },
                file: item.file
            });
            this.uploads.push(upload);

            return upload
                .then((data, status, headers, config) => {
                    item.status = 'success';
                    return {
                        documentId: this.document.id,
                        pageId: i,
                        path: key
                    };
                }, () => {
                    item.status = 'failed';
                    item.progress = 100;
                    return this.$q.reject();
                }, (evt) => {
                    item.progress = Math.round(100.0 * evt.loaded / evt.total);
                })
                .then((file) => {
                    return this.uploader.addFile(file);
                })
                .then((page) => {
                    this.document.pages.push(page);

                    var i = this.items.indexOf(item);
                    if (i != -1) {
                        this.items.splice(i, 1);
                    }
                    // remove items and add pages

                    return page;
                });
        });

        return this.$q
            .all(promises)
            .then((uploadResult) => {
                this.status = undefined;
                if (this.onUpdateHandler) {
                    this.onUpdateHandler(this.document.uploadState);
                }

                return uploadResult;
            }, (uploadDescription) => {
                this.status = UploadStatus.failed;
            });
    }

    upload() {
        this.status = UploadStatus.inProgress;

        return this.uploader
            .uploadDescription(this.document.id)
            .then((uploadDescription) => this.uploadItems(uploadDescription));
    }

    cancel() {
        this.$uibModalInstance.dismiss();
    }

    avaliblePages() {
        var existingPages = this.document.pages && this.document.pages.length || 0;
        var waitingForUpload = this.items && this.items.length || 0;

        return Math.max(0, this.documentDefinition.maximumNumberOfPages - existingPages - waitingForUpload);
    }

    isInProgress() {
        return this.status === UploadStatus.inProgress;
    }

    hasFaild() {
        return this.status === UploadStatus.failed;
    }

    changeUploadType() {

        this.$uibModal
            .open({
                templateUrl: 'controls/changeDocumentType/changeDocumentType.html',
                controller: ChangeDocumentTypeController,
                controllerAs: '$ctrl',
                backdrop: false,
                resolve: {
                    document: () => this.document,
                    showRedCrossCertDoc: () => (this.applicationStatus === 'PLC' || this.applicationStatus === 'TBM' || this.applicationStatus === 'ACT' || this.document.documentType === 53) ?? 0
                }
            })
            .result
            .then(() => { this.$uibModalInstance.close(this.document); })
    }
}

angular
    .module('app')
    .controller('DocumentDisplayModalController', DocumentDisplayModalController);

