
class BeforeYouDepartController {
    beforeYouDepartDetails: any;
    showSuccessText: boolean = false;
    showValidationError: boolean = false;
    isBeforeYouDepartEnabled: boolean = false;
    beforeYouDepartForm: any;
    isOfficeUser: any;
    applicantBirthDate: Date;
    isBeforeYouDepartReadOnly: boolean = false;
        
    static $inject = ['$scope', 'ApplicationPlacement', 'dialogs', '$timeout', 'applicationId', 'applicationObservable', 'beforeYouDepartStatus', 'Auth']
    constructor(private $scope, private ApplicationPlacement, private dialogs, private $timeout, private applicationId, applicationObservable, private beforeYouDepartStatus, Auth) {

        applicationObservable.subscribe(applicationInstance => {
            this.applicantBirthDate = new Date(applicationInstance.birthDate);
            switch (applicationInstance.status) {
                case 'REG':
                case 'OLA':
                case 'AWA':
                case 'TBM':
                    this.isBeforeYouDepartEnabled = false;
                    break;
                default:
                    this.isBeforeYouDepartEnabled = true;
                    break;
            }
        })

        this.isOfficeUser = Auth.user.isOfficer;
        this.ApplicationPlacement.beforeDepartDetails({
            applicationId: this.applicationId
        }).$promise
            .then((data) => {
                this.beforeYouDepartDetails = data;
                this.isBeforeYouDepartReadOnly = (data.isBeforeYouDepartReadOnly == undefined || data.isBeforeYouDepartReadOnly);
            });
    }

    save() {            
        if (this.isValid()) {
            this.$scope.$broadcast('submitted', true);
            this.ApplicationPlacement.saveBeforeDepartDetails({
                applicationId: this.applicationId
            }, this.beforeYouDepartDetails)
                .$promise
                .then(() => {
                    this.beforeYouDepartForm.$setPristine()
                    $('html, body').animate({ scrollTop: 0 }, 200);
                    this.showSuccessText = true;
                    this.$timeout(() => { this.showSuccessText = false; }, 3000);
                }, () => {
                    this.dialogs.error('Error', 'We are sorry but your form could not be saved. Please try again in 5 minutes. If this problem continues, please contact us at: <a target="_blank" href="mailto:support@aupairamerica.co.uk">support@aupairamerica.co.uk<a>');
                });
        }
    }

    approve() {
        this.ApplicationPlacement.saveBeforeDepartStatus({
            applicationId: this.applicationId
        }, { applicationId: this.applicationId, beforeYouDepartStatus: 5 })
            .$promise
            .then(() => {
                this.beforeYouDepartStatus.beforeYouDepartStatus = 5;
            })
    }

    isValid() {
        var beforeYouDepart = this.beforeYouDepartDetails;
        this.showValidationError = true;
        var isValidForm = false;
        if ((
            (
                beforeYouDepart.isMedicalUpdate && beforeYouDepart.appointmentMotive && beforeYouDepart.appointmentDate && beforeYouDepart.appointmentOutcome && beforeYouDepart.treatmentMedication && beforeYouDepart.appointmentDate > this.applicantBirthDate)
                || beforeYouDepart.isMedicalUpdate == false
            )
            && beforeYouDepart.isAmericaHandbook && beforeYouDepart.isPlacementPackHandbook
            ) {
            isValidForm = true;
            this.showValidationError = false;
        }
        return isValidForm;
    }
}

angular
    .module('app')
    .controller('BeforeYouDepartController', BeforeYouDepartController);
