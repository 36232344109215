import { SectionController } from '../SectionController';
import { NoteItemId, ItemGroupId } from '../../../models/ItemGroup';
import { ReviewPage } from '../../../services/ReviewService';
import { ReviewItemId } from '../../../models/ReviewItem';

class ChildcareController extends SectionController {
        

    preferences: any;
    previousExperience: any;
    dce: any;    
    displayApproveButton: boolean;
    isOfficeUser: boolean;
    isApplicant: boolean;
    stickyNodeMode: string;
    page: ReviewPage;
    applicationStatus: string;

    dceSnapshots;

    static override $inject = ['$rootScope', '$scope', '$q', '$state', 'dialogs', '$uibModal', 'ChildcarePreferences', 'PreviousExperience', 'ChildcareExperience', 'childcareExperiencePage', 'applicationId', 'ApplicationUploader', 'review', 'ApproveChanges', 'Auth', 'ReevaluateSection', 'applicationObservable'];
    constructor($rootScope: angular.IRootScopeService, $scope: angular.IScope, private $q: ng.IQService, private $state, dialogs, private $uibModal, private ChildcarePreferences, private PreviousExperience, private ChildcareExperience, childcareExperiencePage, applicationId, private ApplicationUploader, review, private ApproveChanges, Auth, ReevaluateSection, applicationObservable) {
        super($rootScope, $scope, Auth, ReevaluateSection, review, applicationId, ItemGroupId.ChildcareExperience, applicationObservable, dialogs);

        this.preferences = childcareExperiencePage.preferences;
        this.previousExperience = childcareExperiencePage.previousExperience;
        this.dce = childcareExperiencePage.dce;

        this.isOfficeUser = Auth.user.isOfficer;
        this.isApplicant = Auth.user.isApplicant;

        this.page = review.getPage(ItemGroupId.ChildcareExperience);
        this.displayApproveButton = this.page && this.page.approveChange && this.isOfficeUser;

        this.dceSnapshots = {};
        const dceItem = this.page.items.find(i => i.type == ReviewItemId.DocumentedChildcareExperience);
        if (dceItem && dceItem.snapshots) {
            for (const snapshot of dceItem.snapshots) {
                this.dceSnapshots[snapshot.referenceId] = snapshot;
            }
        }

        $scope.$watch(() => this.$scope.childcareFrom.$dirty, () => {
            this.$dirty = this.$scope.childcareFrom.$dirty
        })

        applicationObservable.subscribe(i => {
            if (Auth.user.isAgent || Auth.user.isOfficer || i.interviewerId == Auth.user.roleDetails.contactId) {
                this.stickyNodeMode = 'edit';
            }
            this.applicationStatus = i.status;
        })

        $scope.$on('childcare:refresh', (nam, type, data) => {
            this.refreshSection(type, data);
        })
    }

    refreshSection(type, data) {
        if (type == ReviewItemId.ChildcareExperience) {
            this.preferences = data;
        }
        else if (type == ReviewItemId.DocumentedChildcareExperience) {
            var dceData = this.dce.childcareExperience.find(i => i.id == data.id);
            angular.copy(data, dceData);
        }
    }

    addChildrenExperience() {
        this.$uibModal.open({
            templateUrl: 'areas/application/childcare/childcareExperienceModal.html',
            controller: 'ChildcareExperienceController as childExpCtrl',
            backdrop: false,
            size: 'lg',
            resolve: {
                title: () => 'Add your childcare experience entry',
                childcareExperience: () => <any>{ applicationId: this.applicationId }
            }
        }).result.then((data) => {
            this.dce.childcareExperience.push(data);
            return data;
        }).then((data) => this.redirectToReference(data));
    }

    editChildrenExperience(ce) {
        this.editDCE(ce, false);
        //if (ce.isDCELinkedToReference) {
        //    this.dialogs.confirm('Please Confirm', 'This entry is linked to a reference, editing this childcare entry will delete the reference. Are you sure you want to edit the DCE and delete the reference?')
        //        .result
        //        .then(() => {
        //            //Pass true then reference to be deleted if edit DCE
        //            this.editDCE(ce, true); 
        //        });
        //}
        //else {
        //    //Pass false then reference to be deleted if edit DCE
        //    this.editDCE(ce, false);
        //}                       
    }

    editDCE(ce, deleteReference) {
        this.$uibModal.open({
            templateUrl: 'areas/application/childcare/childcareExperienceModal.html',
            controller: 'ChildcareExperienceController as childExpCtrl',
            backdrop: false,
            size: 'lg',
            resolve: {
                title: () => 'Edit your childcare experience entry',
                childcareExperience: () => angular.copy(ce)
            }
        }).result.then((data) => {
            var i = this.dce.childcareExperience.indexOf(ce);
            if (i > -1) {
                this.dce.childcareExperience[i] = data;
                this.sectionChanged(ReviewItemId.DocumentedChildcareExperience, data.id);
            }

            return data;

            }).then((data) => {
                if (!deleteReference) {
                    this.redirectToReference(data);
                }
            });
    }

    removeChildrenExperience(ce) {
        var dialogMessageText = 'Are you sure you want to delete this childcare entry?';

        if (ce.isDCELinkedToReference) {
            dialogMessageText = 'This entry is linked to a reference, deleting this childcare entry will also delete the reference. Are you sure you want to delete?';
        }

        //if isDCELinkedToReference is true then reference to be deleted
        this.deleteDCE(ce, dialogMessageText);
    }

    deleteDCE(ce, dialogMessageText) {
        this.dialogs.confirm('Please Confirm', dialogMessageText)
            .result
            .then(() => {
                this.ChildcareExperience.remove({ applicationId: this.applicationId, id: ce.id })
                    .$promise
                    .then(() => {
                        this.$scope.$root.$broadcast('note:removeNoteByAssignId', { groupId: ItemGroupId.ChildcareExperience, itemGroupId: NoteItemId.ChildcareExperience, assignId: ce.id });

                        var i = this.dce.childcareExperience.indexOf(ce);
                        if (i > -1) this.dce.childcareExperience.splice(i, 1);
                    });
            });
    }

    redirectToReference(childcare) {
        if (childcare.isValid) {
            this.dialogs
                .confirm('Please Confirm', 'Do you want to add reference ?') // TODO
                .result
                .then(() => this.$state.go('^.references', { childcareId: childcare.id }));
        }
        return childcare;
    }

    addReference(childcareId) {
        this.$state.go('^.references', {
            childcareId: childcareId
        });
    }

    getChildcareConfig(item) {
        if (!item.data) {
            item.data = {
                uploader: new this.ApplicationUploader(this.applicationId, 17, item.id),
                hideDescription: true,
                msg: {
                    upload: 'Upload Completion Certificate',
                    uploaded: 'View Completion Certificate',
                    approved: 'Approved Completion Certificate'

                }
            }
        }
        return item.data
    }

    save() {
        this.$scope.$broadcast('submitted', true);

        var promisses = [];
        var form = this.$scope.childcareFrom;

        if (form['ChildcareExperience'].$dirty) {
            this.preferences.metaform = form['ChildcareExperience'].$metaformVersion;
            promisses.push(
                this.ChildcarePreferences.update(this.preferences)
                    .$promise
                    .then(() => this.sectionChanged(ReviewItemId.ChildcareExperience))
            );
        }

        if (form['PreviousExperience'] && form['PreviousExperience'].$dirty) {
            var previousExperience = angular.copy(this.previousExperience, {});
            previousExperience.metaform = form['PreviousExperience'].$metaformVersion;
            
            promisses.push(
                this.PreviousExperience.update(previousExperience)
                    .$promise
                    .then(() => this.sectionChanged(ReviewItemId.PreviousExperience))
            );
        }

        this.$q.all(promisses)
            .then(() => {
                form.$setPristine();
                //TODO move this to parent class and remove saved flag
                this.alerts.push({ // TODO should I be able to display the same message twice?
                    type: 'success',
                    msg: 'Save complete',
                    dismissOnTimeout: 5000
                })
            }, () => {
                //TODO move this to parent class and remove saved flag
                this.alerts.push({ // TODO should I be able to display the same message twice?
                    type: 'danger',
                    msg: 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:support@aupairamerica.co.uk" > support@aupairamerica.co.uk</a>',
                    dismissOnTimeout: 20000
                })
            });
    }

    changesApproved() {

        var user = this.Auth.user
        var data = {
            applicationId: this.applicationId,
            item: ItemGroupId.ChildcareExperience,
            author: user.firstName + ' ' + user.lastName
        };

        this.ApproveChanges
            .update({ applicationId: this.applicationId }, data)
            .$promise
            .then(data => {
                if (this.displayApproveButton === true) {
                    this.displayApproveButton = false;
                    this.page.approveChange = false;
                }

                this.alerts.push({
                    type: 'success',
                    msg: 'Approval complete',
                    dismissOnTimeout: 5000
                });
            }, () => {
                this.alerts.push({
                    type: 'danger',
                    msg: 'We are sorry but this form could not be approved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:support@aupairamerica.co.uk" > support@aupairamerica.co.uk</a>',
                    dismissOnTimeout: 20000
                })
            });
    }
}

angular
    .module('app')
    .controller('ChildcareController', ChildcareController);

