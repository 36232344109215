import { IAlertConfig } from '../../models/bootstrap/Alert' 
import { ApplicationStatus } from './ApplicationStatus'
import { ReviewPage } from '../../services/ReviewService'
import { ItemGroupId } from '../../models/ItemGroup'
import { ReviewItemId } from '../../models/ReviewItem';
import { SectionChangedEvent } from '../../models/SectionChangedEvent';

export class SectionController {
    snapshots

    $dirty: boolean = false

    displayReadOnly: boolean
    displayProgramReadOnly: boolean

    alerts: IAlertConfig[] = []
    ReviewItemId = ReviewItemId

    private applicationInstance;

    constructor(protected $rootScope: angular.IRootScopeService, protected $scope, protected Auth, protected ReevaluateSection, protected review, protected applicationId: number, private groupId: ItemGroupId, protected applicationObservable, protected dialogs) {
        $scope.$watch(() => this.review.getPage(groupId), (newValue, oldValue) => {
            if (newValue !== oldValue) {
                this.populateData(newValue)
            }
        })
        this.review.getData()

        var page = this.review.getPage(groupId)

        let hasNote = false;
        for (let page of this.review.pages) {
            if (page.hasNote) {
                hasNote = true;
            }
        }

        applicationObservable.subscribe(applicationInstance => {
            this.applicationInstance = applicationInstance;
            if (this.Auth.user.isApplicant) {
                this.displayReadOnly = !(
                    (applicationInstance.status == ApplicationStatus.Online) ||
                    (hasNote && [ApplicationStatus.ToBeMatched, ApplicationStatus.AwaitingAssessment].includes(applicationInstance.status)) ||
                    (applicationInstance.isFormBeingEdited && [ApplicationStatus.ToBeMatched].includes(applicationInstance.status))
                );
            } else {
                this.displayReadOnly = !(applicationInstance.status == ApplicationStatus.Online || hasNote || (applicationInstance.status == ApplicationStatus.ToBeMatched && applicationInstance.isFormBeingEdited));
            }
            this.displayProgramReadOnly = applicationInstance.status == ApplicationStatus.Online;
        })



        this.populateData(page)
    }


    populateData(page: ReviewPage) {
        this.snapshots = {}

        for (var item of page.items) {
            if (item.menuItem && item.menuItem.snapshotFormName) {
                this.snapshots[item.menuItem.snapshotFormName] = item.snapshots && item.snapshots.length === 1 && item.snapshots[0].lastViewedSnapshotData;
            }
        }
    }

    public closeAlert(index: number) {
        this.alerts.splice(index, 1)
    }

    public sectionChanged(reviewItemId: ReviewItemId, assignId?: number) {

        const section = this.review.getPage(this.groupId).items.filter(x => x.type === reviewItemId);
        if (section.length === 1) { //ideally it should be call when not OLA, Review part should be done at that time only
            this.$rootScope.$broadcast(`sectionChanged`, {
                reviewItemId: reviewItemId,
                assignId: assignId,
                sectionName: section[0].menuItem.name
            } as SectionChangedEvent);
        }        
    }

    public reevaluateSection(reviewItemId: ReviewItemId) {
        this.ReevaluateSection.get({ applicationId: this.applicationId, itemId: reviewItemId })
    }
}