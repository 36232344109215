import { IAlertConfig } from '../../../models/bootstrap/Alert'
class MyProfileController {

    profileData: any;
    $dirty: boolean = false
    alerts: IAlertConfig[] = [];

    static $inject = ['$scope', '$q', 'ProfileData', 'Auth', 'dialogs', '$state'];
    constructor(private $scope, private $q: ng.IQService, private ProfileData, private Auth, private dialogs, $state) {
        this.profileData = this.ProfileData.query({
            interviewerId: Auth.user.roleDetails.contactId
        }, (res) => {
            this.profileData.directInterviewerUrl = $state.href('app.registerInterviewer', { interviewerId: this.profileData.id }, { absolute: true });
        });

        $scope.$watch(() => this.$scope.profileForm.$dirty, () => {
            this.$dirty = this.$scope.profileForm.$dirty;
        })
    }

    save() {
        this.$scope.$broadcast('submitted', true);
           
        var form = this.$scope.profileForm;

        if (form['InterviewerDetails'].$dirty) {
            this.profileData.metaform = form['InterviewerDetails'].$metaformVersion;
            this.ProfileData.update({
                interviewerId: this.Auth.user.roleDetails.contactId
            }, this.profileData)
                .$promise
                .then(() => {
                    form.$setPristine();
                    this.alerts.push({ // TODO should I be able to display the same message twice?
                        type: 'success',
                        msg: 'Save complete',
                        dismissOnTimeout: 5000
                    });
                }, () => {
                    this.dialogs.error('Error', 'We are sorry but your form could not be saved. Please try again in 5 minutes. If this problem continues, please contact us at: <a target="_blank" href="mailto:support@aupairamerica.co.uk">support@aupairamerica.co.uk<a>');
                });
        }
    }

    closeAlert(index: number) {
        this.alerts.splice(index, 1)
    }
}

angular
    .module('app')
    .controller('MyProfileController', MyProfileController)