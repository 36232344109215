
export class ApplicationPageErrorsController {

    title

    static $inject = ['name', 'invalidSections', '$uibModalInstance']
    constructor(name, private invalidSections, private $uibModalInstance) {
        this.title = `${name} - invalid questions`;

    }

    close() {
        this.$uibModalInstance.dismiss()
    }
}
